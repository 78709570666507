/* eslint-disable dot-notation */
/* eslint-disable comma-dangle */
import axios from 'axios'
import { API_BASE_URL } from '@/constants'

const api = axios.create({
	baseURL: API_BASE_URL,
})

// set default platform header
api.defaults.headers.common['Platform'] = 'Mobile'

// response interceptors
api.interceptors.response.use(
	(response) => {
		if (response) {
			const { statusCode } = response?.data
			// if not authorized then redirect to unauthorized
			if (statusCode === 401) {
				window.location.href = '/unauthorized'
			}
		}

		return response
	},
	(error) => Promise.reject(error)
)

export const Api = (token) => {
	if (typeof token === 'string') {
		api.defaults.headers.common['Authorization'] = token
	}
	return api
}

export default undefined
