import { BASE_URL } from '@/constants'

const AuthMixin = {
	data() {
		return {
			authToken: this.$route.query.token,
			baseUrl: BASE_URL,
		}
	},
}

export default AuthMixin
